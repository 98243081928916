import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from '@pancakeswap/uikit/dist/theme'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@100;200;300;400;500;600;700;800;900&display=swap');
:root{
  // --themeclr: #2cc2a4;
  --themeclr: #fff;
  --second: #da1524; 
}
  * {
    font-family: 'Kanit', sans-serif !important;
  }
  
  body {
    background-color: #060606 !important;
  }
  .theme-btn:hover,.confirm-expert-mode:hover{
    background:#da1524 !important;
    border:1px solid #da1524 !important;
    color: #fff !important;
     opacity:1 !important;
  }
  a{
    // color:var(--themeclr) !important;
    // background:var(--second) !important
    text-decoration:none  !important
  }
  a[href="/swap"]:hover,a[href="/liquidity"]:hover,a[href="/farms"]:hover
  ,a[href="/pools"]:hover,a[href="/farms/history"]:hover,a[href="/pools/history"]:hover{
     color: #fff !important;
  // background-image: linear-gradient(135deg,#44BC59 0%,#44BC59 100%);
  // -webkit-text-fill-color: transparent;
  // background-clip: text;
  
  // text-fill-color: transparent;
}
  }
  button[scale="sm"]
  {
    background-color: #cd9933;
    box-shadow: none;
    color: #fff;
    border-color:#cd9933;
  }
  button[scale="sm"]:hover {
    background: #3b3737;
    color: #fff !important;
    border-color:#cd9933;

    opacity:1 !important
  }
  #swap-currency-input button[scale="sm"]:hover div,
  #swap-currency-output button[scale="sm"]:hover div
  {
    color: #fff !important;

  }
  #swap-currency-input button[scale="sm"]:hover svg,
  #swap-currency-output button[scale="sm"]:hover svg

  {
    color: #fff !important;
    fill: #fff !important;

  }
  #open-settings-dialog-button, #open-settings-dialog-button:hover,
  #clickFarmCardView,#clickFarmCardView:hover,#clickFarmTableView,#clickFarmTableView:hover
  {
    background:transparent !important;
  }
  // a[rel="noreferrer noopener"]:hover{
  //   color:gray !important
  // }
  .blacktext *{
    color:#000;
    fill:#000
  }
  nav > div:first-child {
    max-width: 100% !important;
    width: 100%;
    justify-content: space-between;
  }
  .theme-btn{
    color:#000
  }

 
    img {
      height: auto;
      max-width: 100%;
    }
    .fa, .far, .fas {
      font-family: "Font Awesome 5 Free" !important;
    }
    .fab {
      font-family: "Font Awesome 5 Brands" !important;
    }
  .dnone{
  display:none;
  }
  .fixedheader a {
    color: #000;
  }
  .fixedheader svg[color="textSubtle"]{
    fill: #000
  }
  .fixedheader button:hover svg{
    fill: #fff
  }
  .fixedheader div[data-popper-escaped ="false"] a {
    color: #fff;
  }
  nav{
    background: #050400 !important;
    width: 100% !important;
    padding-right:5% !important;
    // max-width: 1200px;
    margin: 0 auto !important;
    position: relative !important;
    // top: 0px !important;
    // gap:15px;
    height: 77px !important;
  }
  nav + div {
    margin-top: 0px !important;
}
nav a
{
  color:#fff !important;
}

.swap_bg h2, .liquiditysec h2, .farm_page_bg h1 {
  font-weight: bold;
  display: inline-block;
  background-image: linear-gradient(135deg, #cd9933 0%, #cd9933 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
nav > div {
  align-items: center;
}
  #swap-currency-input div, #swap-currency-input input, #swap-currency-input input::placeholder,
  #swap-currency-output div, #swap-currency-output input, #swap-currency-output input::placeholder {
    color: #fff !important;
    opacity: 1 !important;
}

#swap-page svg

{
  fill: #cd9933 !important;

}

#swap-currency-input button svg,
#swap-currency-output button svg

{
  fill: #fff !important;
}



#join-pool-button:hover{
  opacity: 1 !important;
  background: var(--themeclr) !important;
}
.setting_title h2 {
  color:#cd9933;
}

.navWrapperMain {
  background-color: rgb(238, 238, 238) !important;
  box-shadow: rgba(133, 133, 133, 0.1) 0px -2px 0px -8px inset;
  border-top: 0px solid rgb(129, 128, 224);
  border-bottom: 1px solid rgb(129, 128, 224);
  margin-top: -1px;
}
.nav_wrapper{
  position: relative;
max-width: 1200px;
margin: 0 auto;
}
.nav_wrapper > div{
  background: transparent !important;
}
.pancake:nth-child(2){
  dispaly:none !important;
  visibility: hidden;
}
.pancake:nth-child(3){
  dispaly:none !important;
  visibility: hidden;
}
.butter {
  dispaly:none !important;
  visibility: hidden;
}
.pancake:nth-child(1) {
	background: var(--themeclr);
	box-shadow: 0 1.5px 0 1.5px #8a8daf ;
}
// .connect_wallet_heading{
//   color: var(--themeclr) !important;
// }
a.bnbscan:hover{
  color: #cd9933 !important;
  opacity:0.8
}
.hover_button:hover.hover_button div{
  color: var(--themeclr) !important;
}
.token-amount-input , input{
  color: #da1524 !important;
}
#import-pool-link:hover{
  border-color:#000 !important;
  color:#000 !important;
}
.headong h2{
 color:var(--themeclr)
}
.open-currency-select-button:hover.open-currency-select-button div,.open-currency-select-button div {
  color:#fff !important;
}

.open-currency-select-button:hover.open-currency-select-button svg,.open-currency-select-button:hover svg,.open-currency-select-button svg {
  color:#fff !important;
  fill:#fff !important;

}

#token-search-input{
  color:var(--themeclr);
}

.light_card_content {
 border:none;
}
// .bottom_conrtnet div{
//   color:var(--themeclr) !important;
// }

.input_new input{
 color:var(--themeclr);
}
#farms-table > table > tbody > tr > td > div{
color:#000;
}
.star_hidden {
	visibility: hidden;
}
.dog_img img{
 width:80%;
}
.font_light {
	font-weight: lighter !important;
	font-size: 17px;
	line-height: 26px;
}
.clas_img_img img{
	width: 70%;
	margin: 11px auto;
	display: block;
}
h2.text_c {
	text-align: center;
}
.justifua {
	justify-content: center;
}
.rowContent{
  display: flex;
  margin-top: 30px;
}
.rowContent.top50{
  margin-top: 50px;
}
.grid4{
  width: 40%;
  max-width: 452px;
  padding: 0 15px
}
.grid6{
  width: 50%;
  max-width: 678px;
  padding: 0 15px
}
.grid8{
  width: 60%;
  max-width: 700px;
  padding: 0 15px
}
.grid12 {
  width: 100%;
  max-width: 1152px;
  padding: 0 15px
}
.flexRow{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 22px;
}
.titleBox{
  margin-bottom: 25px;
}
.titleBox h2{
  font-size: 24px;
  margin-bottom: 0px;
}
.whiteBox{
  background: #403e3e;
  border-radius: 15px;
  padding: 15px;
  width: 100%;
}
.whiteBox.greyBox{
  background-color: #403e3e;
  border-radius: 50px 50px 50px 50px;
  border-style: solid;
  border-width: 3px 3px 3px 3px;
  border-color: var(--themeclr);
  padding: 35px 35px 35px 35px;
}
.whiteBox.greyBox .listBox{
  font-weight: 700;
}
.whiteBox.greyBox .listBox h3{
  font-weight: 700;
}
.whiteBox.greyBox .titleBox h2 {
  color: var(--themeclr);
  font-size: 20px;
  font-weight: 700;
}
.whiteBox.greyBox .purbleRoundBg{
  font-family: 'Kanit', sans-serif;
  font-size: 17px;
  font-weight: bold;
  line-height: 28px;
  letter-spacing: 0px;
  background-color: var(--themeclr) ;
  padding: 12px 40px 12px 40px;
  color: #1C1B19 ;
}
.purbleRoundBg {
  background-image: linear-gradient(23deg,#cd9933, #cd9933);
  color: #fff !important;
    border: 1px solid #cd9933 !important;
    white-space:nowrap;
    box-shadow:0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
    transition:none !important;
    padding-top:8px !important;
    padding-bottom:8px !important;
    border-radius:11px !important;
    padding-left:20px;
    padding-right:20px;
}
.listBox span{
  color: #cd9933;
  font-size:16px;
  line-height:20px;

}
.listBox h3{
  color: #da1524;
  font-weight:500 !important;
  margin: 0;
  font-size:16px !important;
  line-height:20px;
  
}
.banner_sec
{
  position:relative;
  // background-image: url("../images/banner_sec_pink.png");
  // background-position:50%;
  // background-size: cover;
  // min-height: 400px;
}
.banner_sec_eighth::after
{
  content:none !important; 
}
.flexFs
{
  display:block !important;
}
.fsBox
{
  width:100% !important;
}
.fsBox.text-right
{
  text-align:left !important;
}
.heade_align_flex
{
  display:flex;
  align-items:flex-start;
  justify-content:space-between;
}
.roadrTreeImg {
    width: 100%;
    border-radius: 15px;
}
.treeContent {
    position: relative;
}
.buttonAbsolute {
    position: absolute;
    top: 85%;
    left: 30px;
    z-index: 1;
}
.greenButton{
  background: #4e9c66;
  color: var(--themeclr);
  padding: 8px 18px;
  border-radius: 8px;
  transition: .5s;
}
.greenButton:hover{
  background: var(--themeclr);
}
.grid5{
  width: 100%;
  max-width: 425px;
  padding: 0 15px;
}
.grid2{
  width: 100%;
  max-width: 302px;
  padding: 0 15px;
}
.buttonGroupFlex {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.greenStakeButton button{
  background: #519c67;
}
.buttonGroupFlex button img{
  margin-right: 7px;
}
.whiteBox.tvlBox {
    align-content: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    text-align: center;
}
.whiteBox.tvlBox.tvlBoxFlexStart{
  justify-content: flex-start;
  padding-top: 30px;
}
.tvlBox h3{
  font-size: 24px;
  margin-bottom: 0px;
}
.tvlBox h2{
  font-size: 38px;
  margin: 20px 0;
  color: var(--themeclr);
}
.tvlBox p{
  font-size: 18px;
  margin-bottom: 0px;
  color: #4e9c66;
}
.farmStakBox{
   padding-top: 30px;
}
.flexColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.farmStakBox h2{
  color: var(--themeclr);
  font-size: 20px;
  font-weight: 700;
}
.flexFs{
  display: flex;
}
.flexFs span
{
  color: #cd9933;
    font-size: 16px;
}
.fsBox {
    width: 50%;
    margin-top: 20px;
}
.fsBox h3{
  color: var(--themeclr);
  font-size: 15px;
  text-transform: uppercase;
  line-height: 53px;
  font-weight: 700;
}
.fsBox h5, .fsBox p{
   color: #da1524;
  font-size: 16px;
  margin-top:20px;
}
.titleImg{
  margin-top: 15px;
}
.abooutPageTitle{
  font-size: 36px;
  margin-bottom: 15px;
  text-align: center;
}
.aboutContentTitle{
  font-size: 24px;
  margin-bottom: 15px;
  margin-top: 30px;
  text-align: center;
}
.aboutButton{
  margin-left: 8px;
}
.aboutButton button{
  background: #e78638;
  color: var(--themeclr);
  border-color: #e78638;
}
.purpleButton{
  background: var(--themeclr);
  color: var(--themeclr);
  padding: 8px 18px;
  border-radius: 8px;
  transition: .5s;
}
.purpleButton:hover{
  background: #4e9c66;
}
.blockButton .purpleButton{
  margin-top: 25px;
  display: block;
  width: 100%;
  text-align: center;
   padding: 12px 18px;
}
.treeDayBox {
  text-align: center;
}
.treeDayBox h3{
  font-size: 20px;
  color: var(--themeclr);
  margin-bottom:12px;
}
.artTree{
  max-height: 190px;
}
.fsBox{
  position:relative;
}

.earnBox h3 a i span{
  display: none;
}
.grid3{
  width: 32%;
  max-width: 400px;
  padding: 0 15px
}
.earnBox h2{
  font-size: 20px;
  margin: 8px 0;
  color: #4e9c66;
  font-weight: 500;
}
.earnBox h3{
  display:flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  margin-bottom: 0px;
}
.earnBox h3 a{
  font-size: 16px;
  background: var(--themeclr);
  color: var(--themeclr);
  padding: 8px 15px;
  border-radius:30px;
  display:inline-block;
  transisation:.5s;
}
.earnBox h3 a:hover{
  background: #4e9c66;
}
.tree_row_secton {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.referralContent h4 {
  font-size: 16px;
  font-weight: 400;
  background: #4d9c65;
  padding: 14px 10px;
  border-radius: 30px;
  color: var(--themeclr);
  margin: 15px 0;
}
.about_content h1{
	font-size: 36px;
	margin-bottom: 22px;
	color: var(--themeclr);
}
.about_content.referralContent h1{
  text-align: left;
  font-size: 24px;
  color: var(--themeclr);
  line-height: 36px;
  margin: 15px 0 0;
}
.referralContent p {
  text-align: left;
}
.about_content p {
	color: var(--themeclr);
	line-height: 26px;
	font-size: 17px;
	font-weight: lighter !important;
}
ul.referralContent_list {
  padding-left: 30px;
  list-style: circle;
}
.referralContent_list li {
  color: var(--themeclr);
	line-height: 26px;
	font-size: 16px;
  margin-bottom: 10px;
}
.space_dog .logo_home {
	width: 81px;
}
.space_dog h3 {
	text-align: center;
	color: var(--themeclr);
}
.about_content {
	margin: 42px auto;
  text-align: center;
}
.justifua {
  margin-top: 15px;
}
.grid_11 {
	width: 100%;
	max-width: 460px;
	padding: 0 15px;
}
// .cololr_green{
//   color:var(--themeclr) !important;
// }
// .cololr_green svg{
//   fill: var(--themeclr);
// }
.bg_white {
	background: var(--themeclr);
	border: none;
}
.top_headein_ss {
	text-align: center;
	padding: 19px 0px;
	font-size: 20px;
	color: var(--themeclr);
}
.clas_img_img {
	background: var(--themeclr);
	border-radius: 10px;
	margin: 0 15px;
	margin-top: 26px;
}
.headin_new{
  font-size: 36px;
  margin-bottom: 0;
}
.partnerLogoMain{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}
.partnerLogo{
  max-width: 80px;
  max-width: 200px;
  margin: 0 30px;
}
.homeHarvestCard {
    position: relative !important;
    margin-bottom: -50px;
    top: -97px !important;
}
.aboutPageBgTop{
  background: url("../images/aboutbg.jpg") no-repeat scroll center bottom;
  background-size: cover;
  min-height: 400px;
  font-size: 0;
  position: relative;
  margin-bottom: 75px;
}
.wriseAboutLogo{
  background: url("../images/aboutlogo.png") no-repeat scroll center bottom;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  position: absolute;
  bottom: -75px;
  left: 50%;
  margin-left: -75px;
  max-width: 100%;
}
.socialFooter i span{
  display: none;
}
.socialFooter{
  padding: 8px 0;
  background-image: linear-gradient(23deg,#26913C, #26913C);
  position: absolute;
  width: 100%;
  left: 0;
  top: 0px;
  display: none;
}

.socialFooter ul{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  max-width: 1200px;
  margin: 0 auto;
}
.socialFooter ul li{
  margin: 7px;
}
.socialFooter ul li a{
  padding: 6px;
  font-size: 20px;
  color: #fff;
  transition: .5s;
}
.socialFooter ul li a:hover{
  color: #29c6a1;
}
.farm_page_bg {
  background: #060606 !important;
}

.connect_modal button {
  opacity: 1 !important;
}
.connect_modal button.hover_button:hover div {
  color: var(--themeclr) !important;
}
.earn_section .grid3 {
	width: 25%;
}
.refer_section_borrn{
  width: 100%;
}
.refer_section_borrn  .input_icon {
	display: flex;
	border: 1px solid #ccc;
	padding: 4px 7px;
	width: 100%;
	border-radius: 5px;
  margin: 13px auto;
}
.refer_section_borrn  .input_icon input {
	width: 100%;
  color: var(--themeclr);
	border: none;
}
.refer_section_borrn  .input_icon i{
	color: #4e9c66;
  cursor: pointer;
}
.count_section  {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
}
.count_section p {
	margin: 7px 0px;
}
.count_section p.color_green {
	color: #4e9c66;
}
.custom_menubutton > div {
  background-color: transparent;
  border: none;
}
.custom_menubutton > div a {
  padding: 4px 4px 0px;
  color: rgb(76, 84, 155);
  font-size: 16px;
  font-weight: 400;
  position: relative;
  height: 42px;
  margin-right: 20px;
  background-color: transparent;
}
.custom_menubutton > div a.active {
  background-color: transparent;
  color: rgb(76, 84, 155);
  font-weight: 600;
}
.custom_menubutton > div a.active::before {
  content: "";
  position: absolute;
  bottom: -1px;
  height: 4px;
  width: 100%;
  background: rgb(248,147,30) none repeat scroll 0% 0% !important;
  border-radius: 2px 2px 0px 0px;
}
h2#info-overview-title {
  font-size: 64px;
  position: relative;
  margin: 16px 0;
}
.info_search {
  position: absolute;
  top: 6px;
  right: 15px;
}
.info_search input {
  height: 32px;
  font-size: 14px;
}
h1 {
  margin-top: 32px;
}
.breadcrumbs {
  position: absolute;
  top: 114px;
  background: rgb(238, 238, 238);
  width: 100%;
  left: 0;
  margin: 0;
  padding: 10px 0;
  border-bottom: 1px solid var(--themeclr);
  display: flex;
  justify-content: center;
  align-items: center;
}
.breadcrumbs li{
  margin-right:20px;
}
.breadcrumbs li a{
  font-size: 16px;
  font-weight: 400;
  padding: 4px 4px 0px 4px;
  height: 42px;
}
.breadcrumbs li a:hover {
  color: var(--second) !important;
  // text-decoration: underline;
}
.breadcrumbs li:nth-child(even){
  display: none;
}
.breadcrumbs li div{
  position: relative;
  font-weight: 600;
}
.breadcrumbs li div:before{
  content: "";
  position: absolute;
  bottom: -10px;
  height: 4px;
  width: 100%;
  background-color: var(--themeclr);
  border-radius: 2px 2px 0 0;
}

.voting_inp:checked {
  background-color: var(--themeclr);
}
.text_left {
  text-align: left !important;
}
.text-center {
  text-align: center !important;
}
.align-center {
  align-items: center;
}
.copied_div {
  position: relative;
}
.copied_txt {
  position: absolute;
      top: 15px;
}
.bannerTop{
  min-height: 100vh;
  padding: 0px 0;
  margin-top:-50px;
}
// .bannerTop::before{
//   content: "";
//   position: absolute;
//   right: 0;
//   top: -113px;
//   background: url("../images/img3home1.png") no-repeat scroll right top;
//   width: 941px;
//   height: 694px;
// }
.bannerTop h1{
  font-size: 40px;
  font-weight: 700;
  text-transform: capitalize;
  line-height: 52px;
  color: var(--themeclr);
}
.bannerTop h1 span{
  color: var(--themeclr);
  font-size: 28px;
  font-weight: 900;
  text-transform: capitalize;
  line-height: 52px;
  display: block;
}
.bannerTop h6{
  color: var(--themeclr);
  font-family: 'Kanit', sans-serif;
  font-size: 25px;
  font-weight: 600;
  line-height: 35px;
  margin-top:10px;
}
.bannerAppButton{
  z-index : 1000;
}
.bannerAppButton a{
  margin-right: 20px;
  display: inline-block;
  z-index : 1000;
}
// nav.fixedheader {
//   position: fixed !important;
//   width: 100% !important;
//   justify-content: space-between;
//   margin: auto !important;
//   left: 0;
//   right: 0;
//   top:0 !important;
//   background: #d7dfef !important;
//   max-width: 100%; 
//   z-index:21 !important;
// }

text-white{
  color:"#fff"
}
nav a{
  text-decoration: none !important;
  font-weight:500;
  letter-spacing:1px
}
iframe{
  display: none;
}
a#join-pool-button {
  color: var(--second);
}
a#join-pool-button svg{
  fill: #fff;
}

#btn_hover:hover, span a:hover{
// color:inherit !important
}
a#join-pool-button:hover {
  color: var(--themeclr);
}

.whiteBox.earnBox.whiteShadowBox{
    box-shadow: 0px 0px 54px 0px rgba(129.00000000000003, 129.00000000000003, 224, 0.13);
    padding: 33px 22px 46px 28px;
    margin-bottom: 40px;
}
.whiteBox.earnBox.whiteShadowBox h3{
  font-size: 20px;
  font-weight: 800;
  line-height: 32px;
  color: var(--themeclr);
  font-weight: 700;
  margin-bottom: 10px;
}
.whiteBox.earnBox.whiteShadowBox h4{
  font-size: 17px;
  font-weight: 400;
  color: var(--themeclr);
  margin-bottom: 10px;
  line-height: 28px;
}
.whiteBox.earnBox.whiteShadowBox h2{
  font-size: 17px;
  font-weight: 400;
  color: var(--themeclr);
  margin-bottom: 10px;
  line-height: 28px;
}

.whiteBox.earnBox.whiteShadowBox h5 {
  color: var(--themeclr);
  font-size: 17px;
  font-weight: 700;
  line-height: 28px;
}
.whiteBox.earnBox.purpleBox{

background: #FFCEE9;
  padding: 40px 20px 40px 20px;
height:100%;
cursor:pointer;
padding: 30px 15px!important;
    box-shadow: -1px 0 14px #cf831f!important;
    border-radius: 30px 0 30px 0!important;
  
background: linear-gradient(125deg,#030303 34%,#212121 48%,#030303 76%)
}
.whiteBox.earnBox.purpleBox:hover
{
  border-color:#b7a5c5;
}
.whiteBox.earnBox.purpleBox h3{
  color: #da1524;
  font-family: 'Kanit', sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
}
.whiteBox.earnBox.purpleBox h2{
  color: #da1524;
  font-size: 17px;
  font-weight: 400;
  line-height:25px;
}
.whiteBox.earnBox.purpleBox h4{
  color: #cd9933;
    font-size: 16px;
    font-weight: 700;
  line-height: 28px;
  letter-spacing: 0px;
}
.imgLeftAlign {
  margin-right: 20px;
}
.align_flex_card
{
  display:flex;
  align-items:center;
}
.sparcoDetail{
  position: relative;
  padding:100px 0px 100px 0px;
}
// .sparcoDetail::before{
//   content: "";
//   position: absolute;
//   left: 0;
//   top: 0;
//   background: url("../images/imgbg1-325x1024.png") no-repeat scroll right top;
//   width: 325px;
//   height: 1024px;
//   margin-top: -30%;
// }
// .sparcoDetail::after{
//   content: "";
//   position: absolute;
//   right: 0;
//   bottom: -220px;
//   background: url("../images/imgbg2.png") no-repeat scroll right bottom;
//   width: 415px;
//   height: 1382px;
//   z-index: -1;
// }
.container{
  width: 90% !important;
  max-width: 1200px;
}
.hbLeft h2{
  color: #383c5e;
  font-size: 35px;
  font-weight: 700;
  text-transform: capitalize;
  line-height: 52px;
}
.hbLeft p{
  color: #383c5e;
  font-size: 19px;
  font-weight: 400;
  line-height: 26px;
  margin: 5px 0px 20px 0px;
}
.homeBanking{
  padding: 50px 0;
}
.sparcoProcess{
  padding: 0px 0 80px;
  margin-top:50px
}
.sparcoProcess h2{
  color: var(--themeclr);
  font-size: 35px;
  font-weight: 900;
  text-transform: capitalize;
  line-height: 52px;
  text-align: center;
}
.sparcoProcess h6{
  margin: 25px 0px 77px 1px;
  font-size: 19px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
  color: var(--themeclr);
}
.cardWhite {
  box-shadow: 0px 0px 56px 0px rgba(128.99999999999997, 128, 224, 0.13);
  margin: 22px 60px 20px 15px;
  padding: 26px 30px 43px 38px;
  width: 85%;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 5px;
  background:#3d3d3d
}
.cardWhite.cardPurple{
  background: var(--themeclr);
}
.cardNumber{
  background: url("../images/BG-copy.png") no-repeat scroll 0 0;
  width: 117px;
  height: 109px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 60px;
  font-weight: 700;
  color: var(--themeclr);
}
.cardWhite h4{
  font-size: 20px;
  color: var(--themeclr);
  margin: 15px 0;
}
.cardWhite.cardPurple h4{
  color: #000;
}
.cardWhite p{
  color: var(--themeclr);
  font-size: 17px;
  line-height: 28px;
}
.cardWhite.cardPurple p{
  color: #000;
}
.homeNews h2{
  color: var(--themeclr);
  font-size: 35px;
  font-weight: 900;
  text-transform: capitalize;
  line-height: 52px;
  text-align: center;
}
.homeNews h6{
  margin: 25px 0px 20px 1px;
  font-size: 19px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
  color: var(--themeclr);
}
.blogListHome{
  padding-top: 40px;
}
.blogListHome ul{
  list-style: none;
  margin: 0;
  padding: 0;
}
.blogListHome ul li{
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 18px;
  margin-bottom: 18px;
}
.blogListHome ul li h3{
  font-size: 20px;
  color: var(--themeclr);
  font-weight: 700;
  margin-bottom: 15px;
}
.blogListHome ul li h3 small{
  font-size: 16px;
  color: var(--themeclr);
  margin-top: 10px;
}
.blogListHome ul li p{
  color: var(--themeclr);
  line-height: 28px;
  margin-bottom: 18px;
}
.blogListHome ul li a{
  color: var(--themeclr);
  font-weight: 700;
}
.footerTop
{
  padding-bottom:100px;
  padding-top:30px;
}
.flexFooter {
  display: flex;
}
.gridFooter{
  width: 20%;
}
.gridFooter.widthLarge{
  width: 75%;
}
.gridFooter.WidthSmall{
  width: 25%;
  padding-left: 30px;
}
.gridFooter ul{
  list-style: none;
  margin: 0;
  padding: 0;
}

.d-inlineblock li{
  display: inline-block;
  margin-right:15px;
}
.footerBottom{
  background:linear-gradient(125deg,#030303 34%,#212121 48%,#030303 76%)

  background-color:#030303;
  padding: 20px 0;
}
.flex_foter_bo
{
  display:flex;
  align-items:center;
  justify-content:center;
}
.footerBottom p{
  max-width: 90%;
  text-align: center;
  font-size:14px !important;
  // margin: 0 auto;
  color: var(--themeclr);
  line-height: 28px;
}
.gridFooter{
  padding: 0 15px;
}

.gridFooter h3{
  color: #da1524;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 10px;
}
.gridFooter ul li a{
  font-size: 14px;
  font-weight: 600;
  line-height: 26px;
  display:block;
  margin-bottom:10px;
  color: #cd9933 !important;
  cursor:pointer;
}
.gridFooter ul li a:hover
{
  color:#fff !important;
}
.gridFooter ul
{
  margin-top:20px;
}
.flex_grid_foter
{
  display:flex;
  flex-direction:column;
}

.footerLogo{
  margin-bottom: 10px;
}
.gridFooter p{
  color:#fff;
  margin: 0;
  line-height: 24px;
  font-size:14px;
}

@media screen and (max-width: 900px) {
  #homepage-hero {
      width: 100%;
      margin-top: 99px;
  }
.partnerLogoMain{
  flex-wrap: wrap;
}
.partnerLogo {
	margin: 7px 9px;
}
.about_content h1 {
	font-size: 30px;
}
.titleBox h2 {
	font-size: 20px;
}
.farmStakBox h2 {
	font-size: 20px;
}
.fsBox h3 {
	font-size: 17px;
}
.tvlBox h3 {
	font-size: 20px;
}
.tvlBox h2 {
	font-size: 30px;
}
.earnBox h2 {
	font-size: 15px;
}
.bMPnRQ .mobile-icon {
	width: 130px;
}
.info_search {
  position: relative;
  top: unset;
  right: unset;
  justify-content: center;
  display: flex;
}
.nav_wrapper {
  background: var(--themeclr);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.info_search {
  margin: 5px 10px 10px;
}
}

@media screen and (max-width: 780px) {
  .homeHarvestCard {
    top: -34px !important;
}
  .rowContent {
    flex-wrap:wrap;
  }
  .grid3 {
    width: 100%;
    max-width: 100%;
    padding: 0 15px;
  }
  .grid8 {
    width: 100%;
    max-width: 100%;
    padding: 0 15px;
  }
  .grid4 {
    width: 100%;
    max-width: 100%;
    padding: 0 15px;
  }
  .roadrTreeImg {
    margin-top: 50px;
  }
  .rowContent > div{
    margin: 14px 0px;
  }
  .bMPnRQ .mobile-icon {
    width: 134px;
  }
  .clas_img_img img {
    width: 46%;
  }
  .about_content h1 {
    font-size: 29px;
  }
  .fsBox:first-child::before {
    right: 6px;
    display:none;
  }
  .flexFooter{
    flex-direction: column;
  }

  .gridFooter.widthLarge{
    width: 100%;
  }
  .gridFooter.WidthSmall{
    padding-left: 0;
    width: 100%;
    margin-top: 30px;
  }
  .gridFooter{
    width: 100%;
    padding: 0;
    margin-top: 30px;
  }
}
@media(max-width:767px){
  .farm_page_bg.left_img_none
  {
    padding-bottom:150px;
  }
  
  .farm_page_bg_abt.farm_page_bg:after
  {
    content:none !important;
  }
  .img_positin_baner
  {
    margin-top:30px;
  }
  .card_blk
  {
    text-align:center;
  }
  .flex_cen_card
  {
    align-items:center !important;
    justify-content:center !important;
  }
  .img_sec_aft::after
  {
    right:0px !important;
  }
  .flex_foter_bo
  {
    display:block !important;
    text-align:center !important;
  }
  .flex_foter_bo .text-right
  {
    text-align:center !important;

  }
  .bannerTop {
    min-height: unset !important;
    padding: 0px 0;
    padding-bottom:50px !important;
}
.bannerTop h6
{
  font-size:20px !important;
  line-height:28px !important;
}
.flex_card_div
{
  display:block !important;
  text-align:center;
}
.flex_cen_card
{
  padding-right:0px !important;
}
.flex_cen_card .btn_green_outline
{
display:block;
margin:0 auto;
}
.flex_card_div img
{
  margin-top:20px;
}
.row_rev_mov
{
  flex-direction:column-reverse;
}
.white_text_large
{
  font-size:18px !important;
  line-height:25px !important;
  color:#cd9933;
}
}
@media(max-width:575px){
  .bannerTop .theme-btn
  {
    padding:8px 11px !important;
  }
  .heade_align_flex  img
  {
    max-width:45px !important;
    min-width:45px !important;

  }
  .banner_sec
  {
    padding-bottom:45px !important;
  }
  .footerTop
  {
    padding-bottom:30px !important;
  }
  .img_positin_btc_elem
  {
    bottom: 4px !important;
    right: 0px !important;
    max-width: 92px !important;
    display:none;
  }
  .banner_sec_eighth
  {
    background:#212121 !important;
  }
  .sparcoDetail
  {
    padding:0px 0px 30px 0px !important;
  }
  .whiteBox.earnBox.purpleBox
  {
    padding: 40px 10px 40px 10px;
    border:1px solid #cc8628;
  }
  .imgLeftAlign img
  {
    max-width:45px !important;
    min-width:45px !important;

  }
  .bannerTop:after
  {
    content:none !important;
  }
  section.footerBottom {
    margin-bottom: 50px;
  }
  nav{
    width:100% !important
  }
  .socialFooter ul li i {
    font-size: 16px;
  }
  .socialFooter {
    padding: 0px 0;
  }
  nav button {
    font-size: 12px !important;
    padding: 10px !important;
    margin-right: 0 !important;
 }
}



.text_color_farm:hover{
  color:#cd9933  !important;
  opacity:0.9
}

button.theme-btn,#join-pool-button {
  background-image: linear-gradient(23deg,#cd9933, #cd9933);
  color: #fff !important;
    border: 1px solid #cd9933 !important;
    white-space:nowrap;
    box-shadow:0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
    transition:none !important;
    padding-top:5px !important;
    padding-bottom:5px !important;
    border-radius:11px !important;

    
}
button.theme-btn:hover,button.theme-btn:focus,button.theme-btn:focus-visible,
#join-pool-button:hover,#join-pool-button:focus,#join-pool-button:focus-visible
{
  background:#da1524 !important;
  background-color:#da1524 !important;
  border: 1px solid #da1524 !important;

  color: #fff !important;
  transition:none !important;
  padding-top:5px !important;
  padding-bottom:5px !important;
}
.green_txt
{
  color: #da1524 !important;
  font-size:25px !important;
  line-height:35px !important;
  position:relative;
}
.bg_green_img
{
  position:relative;
}
.bg_green_img:before
{
  content:"";
  background-image: url("../images/star_green.png");
  position:absolute;
  top:-22px;
  left:-22px;
  width:41px;
  height:31px;
  background-repeat:no-repeat;

}
.green_txt_bold
{
  font-weight:700 !important;
}
.green_text_big
{
  color:#cd9933 !important;
  font-weight:700;
  font-size:35px !important;
}
.banner_dec
{
  color:#fff;
  font-size:14px;
  font-weight:400 !important;
  line-height:20px !important;
}

.theme-btn {
  background-image: linear-gradient(23deg,#cd9933, #cd9933);
  color: #fff !important;
    border: 1px solid #cd9933 !important;
    white-space:nowrap;
    box-shadow:0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
    transition:none !important;
    padding-top:5px !important;
    padding-bottom:5px !important;
    border-radius:11px !important;

    
}
.theme-btn:hover,.theme-btn:focus,.theme-btn:focus-visible
{
  background:#da1524 !important;
  background-color:#da1524 !important;
  color: #cd9933 !important;
  transition:none !important;
  padding-top:5px !important;
  padding-bottom:5px !important;
  border: 1px solid #da1524 !important;

} 
nav .theme-btn
{
  height:40px !important;
}

nav button:hover svg
{
  color:#cd9933;
  fill:#cd9933 !important;
}

nav svg
{
  color:#fff;
  fill:#fff !important;
}

.bannerTop .theme-btn
{
  max-width:max-content !important;
  padding:8px 30px !important;
}
.bannerTop .theme-btn:hover
{
  color: #fff !important;

}
.flex_home_div .green_txt
{
  color: #26913C !important;
  font-size:20px !important;
  font-weight:600;
  margin-bottom:15px;
}

.flex_home_div .white_txt
{
  color: #fff !important;
  font-size:14px !important;
  font-weight:400 !important;
}
.flex_home_div
{
  display:flex;
}

.green_txt_big
{
  color:#da1524;
  font-size:18px;
  position:relative;
  max-width:max-content;
  line-height:25px !important;
}

.white_text_large
{
  color:#cd9933;
  font-size:30px;
  font-weight:700;
  margin-top:20px;
  line-height:40px;
}
.banner_sec
{
  position:relative;

}
.banner_sec::after
{
  content:"";
  // position:absolute;
  // background-image: url("../images/sec_2_green.png");
  // position:absolute;
  // top: 25px;
  // right: 5px;
  // width: 150px;
  // background-size: contain;
  // height: 120px;
  // background-repeat:no-repeat;
}

.banner_sec::before
{
  content:"";
  position:absolute;
  // background-image: url("../images/sec_2_purple.png");
  position:absolute;
  bottom:-100px;
  left:40%;
  width:114px;
  height:25px;
  // background-repeat:no-repeat;
}
.card_blk
{
  background-color:#080808;
  border-radius:36px;
  border:1px solid #080808;
  cursor:pointer;
}
.card_blk:hover
{
  border:1px solid #44BC59;

}
.flex_card_title
{
  color:#fff;
  font-size:18px;
  line-height:25px;
}
.flex_card_div
{
  display:flex;
  justify-content:space-between;
}
.btn_green_outline
{
  border: 1px solid #44BC59;
border-radius: 28px;
color:#44BC59;
font-size:16px;
background-color:transparent;
padding:8px 30px;
}
.btn_green_outline:hover,
a.btn_green_outline:hover
{
  border: 1px solid #44BC59;
border-radius: 28px;
color:#fff !important;
font-size:16px;
background-color:#44BC59;
padding:8px 30px;
}
.flex_cen_card
{
  display:flex;
  align-items:flex-start;
  flex-direction:column;
  justify-content:center;
  padding-right:20px;
}

.btn_green_fill,.btn_connet
{
  border: 1px solid #44BC59;
border-radius: 28px;
color:#fff;
font-size:16px;
background-color:#44BC59;
padding:8px 30px;
}
.btn_green_fill:hover,
a.btn_green_fill:hover,
.btn_connet:hover
{
  border: 1px solid #44BC59;
border-radius: 28px;
color:#44BC59 !important;
font-size:16px;
background-color:transparent;
padding:8px 30px;
}
.btn_connet
{
  text-align: center;
    max-width: max-content;
    display: block;
    margin: 0 auto;
}
.banner_sec_fourth
{
  position:relative;
}
.banner_sec_fourth::before
{
  content:"";
  position:absolute;
  top:-100px;
  left:calc(50% - 75px);
  width:150px;
  height:150px;
  background-image: url("../images/doodle.png");
  background-repeat:no-repeat;
  background-size:contain;
}

.banner_sec_fourth::after
{
  content:"";
  position:absolute;
  bottom:-0px;
  left:calc(50% - 275px);
  width:100px;
  height:100px;
  background-image: url("../images/doodle_1.png");
  background-size:contain;
  background-repeat:no-repeat;

}
.img_sec_fourct
{
  position:relative;
}
.img_sec_fourct::after
{
  content:"";
  position:absolute;
  width: 100px;
height: 100px;
background: transparent linear-gradient(180deg, #26913CA6 0%, #B87003C7 100%) 0% 0% no-repeat padding-box;
opacity: 0.47;
filter: blur(50px);
right:0px;
top:0px;
}
.card_green_bor
{
  background-color:#17171B;
  border-radius:36px;
  border:2px solid #17171B;
  border-top-color:#44BC59;
}
.img_sec_aft
{
  position:relative;
}


.img_sec_aft::after
{
  content:"";
  position:absolute;
  bottom:-40px;
 right:-50px;
  width:100px;
  height:100px;
  background-image: url("../images/doodle.png");
  background-size:contain;
  background-repeat:no-repeat;

}
.banner_sec_sixth
{
  position:relative;
}

.banner_sec_sixth::after
{
  content:"";
  position:absolute;
  bottom:40px;
 right:50px;
  width:150px;
  height:150px;
  background-image: url("../images/doodle.png");
  background-size:contain;
  background-repeat:no-repeat;

}


.banner_sec_sixth::before
{
  content:"";
  position:absolute;
  width: 80px;
height: 80px;
background: transparent linear-gradient(180deg, #26913CA6 0%, #B87003C7 100%) 0% 0% no-repeat padding-box;
opacity: 0.47;
filter: blur(50px);
right:0px;
top:0px;
}

.img_sec_aft_sev
{
  position:relative;
}


.img_sec_aft_sev::after
{
  content:"";
  position:absolute;
  bottom: -55px;
    right: 60px;
  width:70px;
  height:80px;
  background-image: url("../images/doodle_2.png");
  background-size:contain;
  background-repeat:no-repeat;

}
.card_bor
{
  background-color:transparent;
  border-radius:35px;
  border:1px solid #212121;
  cursor:pointer;
}
.card_bor:hover
{
  background-color:#44BC59;
  border-color:#44BC59;

}

.card_bor:hover .card_bor_desc_green
{
  color:#fff;
}
.card_bor_title
{
 color:#fff;
 font-size:20px;
 text-align:center; 
 font-weight:500;
}

.card_bor_desc_green
{
  color:#44BC59;
  font-size:25px;
  text-align:center; 
 font-weight:600;
   
}
.banner_sec_eighth
{
  position:relative;
  // background: url("../images/bg.jpg") no-repeat scroll center bottom;
  // background-size: 100% 100%;
  // min-height: 400px;
}

.banner_sec_eighth::after
{
  content:"";
  position:absolute;
  width: 210px;
height: 219px;
background: url("../images/green_logo_left.png") no-repeat scroll center bottom;
right:0px;
bottom:-25px;
}
.fooyer_logo
{
  max-width:200px;
}

@media only screen and (min-width:576px) and (max-width:767px)
{
  .imgLeftAlign img,.heade_align_flex img
  {
    max-width:60px !important;
    min-width:60px !important;

  }
  .footerBottom
  {
    padding-bottom:75px !important;
  }
  .footerTop
  {
    padding-bottom:30px !important;
  }
  .banner_sec_eighth
  {
    background:#212121 !important;
  }
  .img_positin_btc_elem
  {
    bottom: 10px !important;
    right: 0px !important;
    display:none;
  }
  .sparcoDetail
  {
    padding:0px 0px 30px 0px !important;
  }
}


@media only screen and (min-width:768px) and (max-width:991px)

{
  .farm_page_bg.left_img_none
  {
    padding-bottom:150px;
  }
  .imgLeftAlign img,.heade_align_flex img
  {
    max-width:72px !important;
    min-width:72px !important;
  }
  .footerTop
  {
    padding-bottom:30px !important;
  }
  .banner_sec
  {
    padding-bottom: 85px;
  }
  .banner_sec_eighth
  {
    background:#212121 !important;
  }
  .sparcoDetail
  {
    padding:0px 0px 30px 0px !important;
  }
}
@media only screen and (min-width:768px) and (max-width:1199px)
{
  .imgLeftAlign img,.heade_align_flex img
  {
    max-width:72px !important;
    min-width:72px !important;
  }
  .img_positin_btc_elem
  {
    display:none;
    bottom:-135px !important;
  }
  .col_right_align_flex_mb
  {
    display:flex;
    align-items:center;
    justify-content:flex-end;
  }
  .bannerTop {
    // min-height: unset !important;
    padding: 0px 0;
    display: flex;
    align-items: center;
    padding-bottom:50px !important;
  }
}

@media only screen and (min-width:992px) and (max-width:1199px)
{
  .imgLeftAlign img,.heade_align_flex img
  {
    max-width:50px !important;
    min-width:50px !important;
  }
}

@media only screen and (min-width:992px)
{
  .banner_sec
  {
    background-size:100% 100% !important;
  }
}

@media only screen and (min-width:1200px)
{
  .imgLeftAlign img,.heade_align_flex img
  {
    max-width:72px !important;
    min-width:72px !important;
  }
  .col_right_align_flex_mb
  {
    display:flex;
    align-items:center;
    justify-content:flex-end;
  }
  .bannerTop
  {
    display:flex;
    align-items:center;
  }
}

@media only screen and (min-width:1200px) and (max-width:1299px)
{
  .card_blk .btn_green_outline
{
padding-left:23px !important;
padding-right:23px !important;

}
}
.theme-btn-bordered
{
  background-image: linear-gradient(23deg,#da1524, #da1524) !important;

  color: #fff !important;
    border: 1px solid #da1524 !important;
    white-space:nowrap;
    box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25) !important;
    transition:none !important;
    padding-top:5px !important;
    padding-bottom:5px !important;
    border-radius:11px !important;
}

.theme-btn-bordered:hover,.theme-btn-bordered:focus{
  background-image: linear-gradient(23deg,#cd9933,#cd9933) !important;
  color: #fff !important;
  border: 1px solid #cd9933 !important;
  border-radius:11px !important;

   opacity:1 !important;
}

.bannerTop
{
  position:relative;
}
nav a, nav button
{
  // z-index:30 !important;
  position:relative !important;
}
.bannerTop:after
{
  content:"";
  // position:absolute;
  // left:40%;
  // top:35%;
  // width:114px;
  // height:111px;
  // background: url("../images/star_purple.png") no-repeat scroll center bottom;

}



// .img_positin_baner
// {
//   position:absolute;
//   right:-75px;
//   top:-75px;
// }

nav
{
  background:#050400;
}

.img_positin_btc_elem
{
  position:absolute;
  bottom: -132px;
  right: 94px;
  max-width: 120px;
  display:none;
}

@media only screen and (max-width:1199px)
{
  .banner_sec::after
  {
    content:none !important;
  }
  .bannerTop
  {
    padding-top:100px;
  }
  .green_text_big
  {
    font-size:30px !important;
  }
  .green_txt
  {
    font-size:20px !important;
    line-height:30px !important;
  }
}

.farm_page_bg
{
  position:relative;
}

.farm_page_bg:before
{
  content:"";
  position:absolute;
  // background-image: url("../images/sec_2_green.png");
  // position:absolute;
  // top: 50px;
  // right: 20px;
  // width: 158px;
  // height: 190px;
  background-size: contain;
  // background-repeat:no-repeat;
}

.farm_page_bg.left_img_none:before
{
  content:"";
  // position:absolute;
  // background-image: url("../images/sec_2_green.png");
  // position:absolute;
  // top: 50px;
  // right: 20px;
  // width: 158px;
  // height: 190px;
  // background-size: contain;
  // background-repeat:no-repeat;
}




.footerTop
{
  // background-image: url("../images/banner_sec_pink.png");
  // background-size:100% 100%;
  // background-repeat:no-repeat;
  padding-top:30px;
  padding-bottom:30px;
  background-color:#0a0a0a;
}





.btn_hover_tab>div,.btn_hover_tab a
{
border-radius:11px !important;
}
.btn_hover_tab a:hover
{
  color:#cd9933 !important;
}

.btn_hover_tab_new>div,.btn_hover_tab_new button
{
border-radius:11px !important;
}
.btn_hover_tab_new:hover
{
  background: #cd9933 !important;

  background-color: #cd9933 !important;
  box-shadow: none;
  color: #fff;
  border-color: #fff;
}
#wallet-connect-metamask div,
#wallet-connect-walletconnect div
{
  color:#da1524 !important;
}
.connect_btn_modal
{
  color:#fff !important;
  background:#cd9933 !important;
}
.connect_btn_modal:hover
{
  background:#da1524 !important;
  opacity:1 !important;
  color:#fff !important;
}
.btn_gide_v:hover,.btn_gide_v:focus
{
  color:#fff !important;
}
@media(max-width:575px){
  .farm_page_bg.left_img_none:before,
  .farm_page_bg:before

  {
    width:100px !important;
    height:70px !important;
    right:0px !important;
  }
  .bannerTop .theme-btn
  {
    padding:8px 11px !important;
  }
}
@media only screen and (min-width:768px) and (max-width:991px)
{
  .bannerTop .theme-btn
  {
    padding:8px 11px !important;
  }
}

@media only screen and (min-width:576px) and (max-width:767px)
{
  .bannerTop:after
  {
    content:none !important;
  }
}

.bannerTop
{
  position:relative;
  background: url("../images/mediabanner.png") no-repeat scroll center bottom;
  background-size: cover;
}
button[scale="sm"].btn_hover_tab_new
{
  background-color:#212121 !important;
}


.hdr_crypto_asset::after {
  content: "";
  background-image: url(../images/icon2.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
  width: 41px;
  height: 25px;
  position: absolute;
  border-radius: 50%;
  right: -2px;
  top: -14px;
}

#coming_trigger
{
  opacity:0;
}
.announcement_text1

{
  color:#fff;
  // max-width:270px;
  max-width:100%;
  word-break:break-word;
  line-height:1.5;
  text-align:center;
}
.announcement_text1 a
{
  color:#cd9933;
}
.announcement_text1 a:hover
{
  color:#da1524;
}
.accept_btn
{
  background:transparent;
  border:none;
  color:#0e8e0e;
  padding-left:0px !important;
}
.accept_btn:hover,.reject_btn:hover
{
  color:#cd9933;

}

.reject_btn
{
  background:transparent;
  border:none;
  color:#da1524;
}
.modal_cookie_positin
{
position:fixed;
bottom:0px;
width:100%;
border-radius:0px !important;
}
.modal_cookie_positin button[aria-label="Close the dialog"]
{
  position: absolute;
    top: 0px;
    right: 0px;
}
.modal_cookie_positin>div:first-child
{
  border-bottom:none !important;
  padding:0px !important;
}
.btn_sec_acc_rej
{
  margin-top:20px;
}
.modal_bdy_height_cook
{
  max-height:unset !important;
  overflow-y:unset !important;
  padding-top:10px;
}
@media only screen and (max-width:576px)
{
  .modal_cookie_positin
  {
    bottom:50px;
  }
}
.comin_modal_title{
  box-shadow:none !important;
}
.cheeseburgers_stats_di{
  flex-wrap:wrap;
}
`
export default GlobalStyle
