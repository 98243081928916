import { ContextApi } from 'contexts/Localization/types'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'CBT',
  description:
    'The most popular AMM on BSC by user count! Earn CAKE through yield farming or win it in the Lottery, then stake it in CBT Pools to earn more tokens! Initial Farm Offerings (new token launch model pioneered by CBT), NFTs, and more, on a platform you can trust.',
  image: 'https://cbt.maticz.in/images/hero.png',
}

export const getCustomMeta = (path: string, t: ContextApi['t']): PageMeta => {
  let basePath
  if (path.startsWith('/swap')) {
    basePath = '/swap'
  } else if (path.startsWith('/add')) {
    basePath = '/add'
  } else if (path.startsWith('/remove')) {
    basePath = '/remove'
  } else if (path.startsWith('/teams')) {
    basePath = '/teams'
  } else if (path.startsWith('/voting/proposal') && path !== '/voting/proposal/create') {
    basePath = '/voting/proposal'
  } else if (path.startsWith('/nfts/collections')) {
    basePath = '/nfts/collections'
  } else if (path.startsWith('/nfts/profile')) {
    basePath = '/nfts/profile'
  } else if (path.startsWith('/pancake-squad')) {
    basePath = '/pancake-squad'
  } else {
    basePath = path
  }

  switch (basePath) {
    case '/':
      return {
        title: `CHEESEBURGER DEFI | ${t('Home')}`,
      }
    case '/swap':
      return {
        title: `CHEESEBURGER DEFI | ${t('Exchange')}`,
      }
    case '/add':
      return {
        title: `CHEESEBURGER DEFI | ${t('Add Liquidity')}`,
      }
    case '/remove':
      return {
        title: `CHEESEBURGER DEFI | ${t('Remove Liquidity')}`,
      }
    case '/liquidity':
      return {
        title: `CHEESEBURGER DEFI | ${t('Liquidity')}`,
      }
    case '/find':
      return {
        title: `CHEESEBURGER DEFI | ${t('Import Pool')}`,
      }
    case '/competition':
      return {
        title: `CHEESEBURGER DEFI | ${t('Trading Battle')}`,
      }
    case '/prediction':
      return {
        title: `CHEESEBURGER DEFI | ${t('Prediction')}`,
      }
    case '/prediction/leaderboard':
      return {
        title: `CHEESEBURGER DEFI | ${t('Leaderboard')}`,
      }
    case '/farms':
      return {
        title: `CHEESEBURGER DEFI | ${t('Farms')}`,
      }
    case '/farms/auction':
      return {
        title: `CHEESEBURGER DEFI | ${t('Farm Auctions')}`,
      }
    case '/pools':
      return {
        title: `CHEESEBURGER DEFI | ${t('Pools')}`,
      }
    case '/lottery':
      return {
        title: `CHEESEBURGER DEFI | ${t('Lottery')}`,
      }
    case '/ifo':
      return {
        title: `CHEESEBURGER DEFI | ${t('Initial Farm Offering')}`,
      }
    case '/teams':
      return {
        title: `CHEESEBURGER DEFI | ${t('Leaderboard')}`,
      }
    case '/voting':
      return {
        title: `CHEESEBURGER DEFI | ${t('Voting')}`,
      }
    case '/voting/proposal':
      return {
        title: `CHEESEBURGER DEFI | ${t('Proposals')}`,
      }
    case '/voting/proposal/create':
      return {
        title: `CHEESEBURGER DEFI | ${t('Make a Proposal')}`,
      }
    case '/info':
      return {
        title: `CHEESEBURGER DEFI | ${t('Overview')} | ${t('CHEESEBURGER DEFI Info & Analytics')}`,
        description: 'View statistics for Pancakeswap exchanges.',
      }
    case '/info/pools':
      return {
        title: `CHEESEBURGER DEFI | ${t('Pools')} | ${t('CHEESEBURGER DEFI Info & Analytics')}`,
        description: 'View statistics for Pancakeswap exchanges.',
      }
    case '/info/tokens':
      return {
        title: `CHEESEBURGER DEFI | ${t('Tokens')} | ${t('CHEESEBURGER DEFI Info & Analytics')}`,
        description: 'View statistics for Pancakeswap exchanges.',
      }
    case '/nfts':
      return {
        title: `CHEESEBURGER DEFI | ${t('Overview')}`,
      }
    case '/nfts/collections':
      return {
        title: `${t('Collections')}`,
      }
    case '/nfts/profile':
      return {
        title: `${t('Your Profile')}`,
      }
    case '/pancake-squad':
      return {
        title: `${t('Pancake Squad')}`,
      }
    default:
      return null
  }
}
