import { Type } from 'react-feather'
import { serializeTokens } from './tokens'
import { SerializedPoolConfig, PoolCategory } from './types'

const serializedTokens = serializeTokens()


export interface Category {
  address?:string
}

export interface Categorymainet {
  address?:string
}
const poolsdatamainet: Categorymainet = {};
const poolsdata: Category = {};


const poolsTokens = JSON.parse(localStorage.getItem("Pools"))
console.log("poolsToken-",poolsTokens);

if(poolsTokens){
  // poolsTokens.map((value)=>{
  //   console.log(value,"valuee--")
  //   if(value.chainId == 43113){   
  //     poolsdata["address"]=value.address
  //   }
  //   if (value.chainId == 56){
  //     poolsdatamainet["address"]=value.address
  //   }
  
  // })

  poolsTokens.map((value)=>{
    console.log("poolsdata",value);
   
    if(value.chainId === 43113){

      poolsdata.address = value.address

      
      return poolsdata
    }
   
    
    if(value.chainId === 56){
      poolsdatamainet.address = value.address
      return poolsdatamainet
    }
    // return value
    return {}
    // }
    // return valuedata
  })
 
}

console.log("TokenPoolssssssssssssss",typeof(poolsdata))

const pools: SerializedPoolConfig[] = [
  // {
  //   sousId: 0,
  //   stakingToken: serializedTokens.sparco,
  //   earningToken: serializedTokens.sparco,
  //   contractAddress: {
  //     43113: '0xc864FBF36F7C32303111a2693841dFf0B222c3CF',
  //     56: '0x966b7089d14b729dCb0452aCBEcF20Ee593415D0',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   harvest: true,
  //   tokenPerBlock: '0.03805175038',
  //   sortOrder: 0,
  //   isFinished: false,
  // },
  {
    sousId: 1,
    stakingToken: serializedTokens.cbt,
    earningToken: serializedTokens.cbt,
    contractAddress: {
      43113: poolsdata.address,
      56: poolsdatamainet.address,
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.03805175038',
    sortOrder: 0,
    isFinished: false,
  }

]
console.log("poolsdata_success",pools);

export default pools
